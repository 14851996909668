button {
  border: none;
  background: none;
  font-family: "Archivo", "PretendardJP", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
}

.App-header {
  min-height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2.5vmin);
  color: black;
  padding: 0 70px;
  letter-spacing: -0.5px;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.footer {
  letter-spacing: -0.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 80px;
  background-color: #eeeeee;
  z-index: 2;
  position: relative;
}

.copyright {
  text-align: left;
}

.earth {
  cursor: pointer;
  font-size: 35px;
}

.country {
  display: none;
  position: absolute;
  right: 55px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 15px;
  top: 980px;
}

.country > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.country > ul > li > button {
  cursor: pointer;
  font-size: 15px;
}

.show {
  display: block;
}

.backdrop {
  position: absolute;
  width: -webkit-fill-available;
  height: 85px;
  backdrop-filter: saturate(180%) blur(10px);
  background-color: hsla(0, 0%, 100%, 0.15);
  z-index: -5;
  left: 0;
}

.hamburger {
  cursor: pointer;
}

.sidemenu {
  position: absolute;
  color: black;
  border-right: 1px solid lightgray;
  left: 0;
  top: 0;
  transform: translateX(-400px);
  transition: 0.5s all ease;
  z-index: 15;
  width: 300px;
  height: 100vh;
  padding: 50px;
  /* backdrop-filter: saturate(180%) blur(10px); */
  background-color: white;
}

.openMenu {
  transform: translateX(0px);
  transition: 0.5s all ease;
}

.sidemenu > div > ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidemenu > div > ul > li {
  width: 200px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 23px;
}

.sidemenu > div > ul > li > a > img {
  width: 200px;
}
