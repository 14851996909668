.discography {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2em;
    padding-top: 145px;
    gap: 15px;
    margin: 0 auto;
    justify-content: center;
}

.discoAlbum {
    width: 255px;
    height: 100%;
    overflow: hidden;
    transition: 0.3s all ease;
    gap: 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-weight: normal;
}

.discoAlbum:hover {
    filter: drop-shadow(0 0 1rem rgb(0, 0, 0, 0.15));
    transition: 0.3s all ease;
}

.discoImage {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
}

.discoText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    letter-spacing: -1px;
    gap: 10px;
}

.discoTitle {
    display: flex;
    flex-direction: column;
}

.discoLocales {
    color: gray;
    font-size: 15px;
}

.discoDetail {
    font-size: 15px;
}

.discoDetail > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px
}

.discoSmalls {
    color: darkgray;
    font-size: 15px;
}