.lyrics {
  white-space: pre-wrap;
  width: 100%;
  height: 75vh;
  text-align: left;
  overflow-y: scroll;
  z-index: 2;
  flex-grow: 1;
}

.lyrics > span {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  line-height: 45px;
}

.lyricsVideos {
  width: 70%;
  flex-grow: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.lyricsHome {
  padding: 165px 70px 70px 70px;
}

.lyricsContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.lyricsLeft {
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 1;
  background-blend-mode: screen;
  top: 0;
  filter: blur(20px) opacity(0.7);
}

.lyricsImage {
  overflow: hidden;
  border-radius: 20px;
}

.lyricsImage > img {
  width: 100%;
  display: block;
}

.video_caption {
  text-align: center;
  color: black;
  font-size: 15px;
  letter-spacing: -1px;
  z-index: 2;
}

.videos {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
