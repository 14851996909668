.home {
    min-height: 100vh;
    padding-top: 85px;
}

.NewestSection {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 70px;
    background: radial-gradient(ellipse at right, rgba(137, 207, 235, 0.4), transparent),
                radial-gradient(ellipse at left, rgba(78, 159, 12, 0.25), transparent);
    gap: 50px;
    overflow: hidden;
    margin: 1.5vw 5vw 5vw 5vw;
    border-radius: 30px;
    border: 2px solid rgba(0, 0, 0, 0.07);
}

.NewestAlbum {
    width: 325px;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.3s all ease;
    gap: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-weight: bold;
}

.NewestAlbum:hover {
    filter: drop-shadow(0 0 1rem rgb(0, 0, 0, 0.15));
    transition: 0.3s all ease;
}

.NewestAlbum > div {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    letter-spacing: -0.7px;
}

.AlbumFive {
    display: flex;
    gap: 50px;
    width: 100%;
    overflow-x: scroll;
}

.hometitle{
    text-align: left;
    font-size: 55px;
    letter-spacing: -2px;
    font-weight: bold;
    margin: 5vw 5vw 0 5vw;
}

.topicHome {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    padding: 5vw;
    gap: 50px;
    width: auto;
    margin: 0 auto;
}

.topicHome > div {
    flex-grow: 1;
}

.topictitle {
    font-size: 40px;
    letter-spacing: -1px;
    font-weight: bold;
    width: 100%;
}

.hide {
    cursor: pointer;
}

.homebanner {
    background-color: #efefef;
    display: flex;
    padding: 25px 50px;
    font-size: 50px;
}

.homebanner > img {
    display: block;
    margin: 0 auto;
}

.infos {
    border-bottom: 1px solid lightgray;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    cursor: pointer;
    transition: 0.2s all ease;
    gap: 5px;
}

.infos:hover {
    background-color: rgba(211, 211, 211, 0.28);
    transition: 0.2s all ease;
}

.topicArticles {
    width: 100%;
    border-radius: 20px;
    border: 1px solid lightgray;
    background-color: rgba(0, 0, 0, 0.015);
}