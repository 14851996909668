@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;400;700;900&display=swap');

@font-face {
    font-family: 'PretendardJP';
    src: url('./styles/fonts/PretendardJP-Black.woff2') format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: 'PretendardJP';
    src: url('./styles/fonts/PretendardJP-Medium.woff2') format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: 'PretendardJP';
    src: url('./styles/fonts/PretendardJP-Light.woff2') format('woff2');
    font-weight: light;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Archivo', 'meiryo', 'PretendardJP',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
