.signForm {
  border: 1px solid black;
  padding: 15vw;
  height: 100%;
}

.signTitle {
  font-size: 40px;
}

.inputSection {
  display: flex;
  flex-direction: column;
}

.inputSection > input {
  padding: 8px;
  border: 2px solid lightgray;
  border-radius: 50px;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
}
